@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,600,400italic,600italic&subset=latin,latin-ext);

html, body {
  font-family: 'Open Sans', 'HelveticaNeue', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  min-height: 100%;
  font-size: 13px;
}

/*
	---------- GENERAL ----------
*/

a {
  text-decoration: none;
}

h1, h2, h3, h4 {
  font-family: 'Open Sans', 'HelveticaNeue', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  margin: 0;
  font-weight: 100;
  color: #f88700;
}

h1, h2 {
  font-size: 24px;
  line-height: 21.2pt;
  margin-bottom: 14px;
  color: #0a5593;
  font-family: gearedslab, "times new roman", times, serif;
  text-transform: uppercase;
}

h1 {
  font-size: 28px;
  line-height: 30px;
}

.selecter-div {
  display: block !important;
}

.highlight {
  background-color: #ffff00;
}

.element-hidden {
  display: none !important;
}

/*
	---------- ICONS ----------
*/

.icon-color-viewswitcher,
.icon-color {
  color: #50a3cb;
}

.icon-color {
  font-size: 18px !important;
}

.custom-icon {
  margin-right: 7px;
}

.icon-color-navigation {
  font-size: 11px !important;
  background-color: #50a3cb !important;
  padding-left: 10px !important;
  width: 30px !important;
}

.icon-color:hover,
.icon-color-navigation:hover {
  opacity: 0.7;
}

/*
	---------- BUTTONS ----------
*/

.default-button,
.default-button-large {
  background-color: #5ac1e8;
  background-position: center center;
  background-repeat: no-repeat;
  border: 0 none;
  border-radius: 3px;
  color: #fff;
  padding: 7px 14px;
  font-size: 14px;
  margin: 0;
  font-family: 'Open Sans', 'HelveticaNeue', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 100;
}

.default-button:hover,
.default-button-large:hover {
  background-color: #84cde8;
}

.default-button-large {
  padding: 11px 28px;
  font-size: 17px;
}

.close-button-modal {
  position: relative;
  top: -5px;
  right: 5px;
}

/*
	---------- TABLE ----------
*/

.site {
  width: 100%;
  height: 100%;
  position: absolute;
  //background: url('../images/background.png') left bottom no-repeat;
}

/*
	---------- HEADER ----------
*/

#header-tr {
  height: 81px;
}

.header-div {
  background-color: #fff;
  border-bottom: 1px solid #ccc;
  height: 82px;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 2;
  opacity: .95;
}

.header {
  max-width: 1180px;
  margin: 29px auto 0 auto;
  .nav > li {
    background: transparent;
  }
}

.header-menu-i {
  font-size: 15px !important;
  color: #000 !important;
  font-family: arial, Helvetica, Arial, "Arial Unicode MS", sans-serif;
  position: relative;
  border-bottom: 2px solid transparent;
  transition: .5s ease-in-out;
  @media (min-width: 980px) {
    &:after {
      content: ' ';
      border-right: 1px solid #ccc;
      height: 20px;
      position: absolute;
      right: 0;
      top: 15px;
    }

    &.no-border:after {
      content: none;
    }
  }
}

.header-menu-i:hover,
.header-menu-selected {
  border-bottom: 2px solid #0a5593;
}

//.header-block-orange, .header-block-green, .header-block-blue, .header-block-banner {
//  position: absolute;
//  width: 90px;
//  height: 51px;
//  z-index: 3;
//}
//
//.header-block-orange {
//  background-color: #f78701;
//  top: 0px;
//}
//
//.header-block-green {
//  background-color: #49bb1b;
//  top: 51px;
//}
//
//.header-block-blue {
//  background-color: #50a3cb;
//  top: 102px;
//}
//
//.header-block-banner {
//  background: url('../images/banner.png');
//  height: 725px;
//  top: 153px;
//  z-index: 0;
//}

.logo-div {
  position: absolute;
  background: url('../images/logo.png') no-repeat left center;
  width: 203px;
  height: 50px;
  display: inline-block;
  z-index: 4;
  top: 30px;
  margin-left: 14px;
  //left:0;
}

.navbar {
  z-index: 2;
}

/*
	---------- CONTENT ----------
*/

.content {
  margin: 0 auto 0 auto;
  position: relative;
  margin-top: -20px;
  z-index: 2;
  font-size: 14px;
  color: #303030;
  padding: 14px 14px 56px;
}

.content-login {
  float: right;
  position: relative;
  bottom: 3px;
}

.content-login-profile {
  font-weight: bold;
}

.content-login-profile, .content-login {
  font-size: 13px;
  color: #808080;
}

.content-login-profile:hover {
  text-decoration: underline;
}

.content-login-icon:before {
  display: none;
  font-size: 20px;
  margin-left: 5px;
  position: relative;
  top: 2px;
}

#breadcrumbs {
  margin-bottom: 14px;
  font-size: 13px;
  color: #808080;
}

#breadcrumbs a:link {
  color: #808080;
}

/*
	---------- FOOTER ----------
*/

#footer-tr {
  height: 1px;
  color: #eee;
  font-weight:bold;
}

.footer-inner {
  max-width: 1180px;
  padding: 0 14px;
  margin: 0 auto;
  &:after {
    display: table;
    clear: both;
    content: ' ';
    height: 1px;
  }
  a {
    color: #fff;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}

.footer-div1 {
  border-top: 1px solid #444444;
  background: #0a5593;
  padding: 50px 0;
  font-size: 16px;
}

.footer-div2 {
  background: #333333;
  padding: 20px 0;
  font-size:11px;
}

/*
	---------- EXTRA ----------
*/

.lokatie-op-kaart {
  float: right !important;
  width: 288px !important;
}

.modal-content {
  padding: 20px !important;
}

.filters-div {
  font-family: Calibri;
  background-color: white;
  color: black;
  padding: 20px 20px 30px 20px;
  width: 220px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  margin-top: -60px;
  -webkit-box-shadow: -4px 3px 5px 0px rgba(50, 50, 50, 0.75);
  -moz-box-shadow: -4px 3px 5px 0px rgba(50, 50, 50, 0.75);
  box-shadow: -4px 3px 5px 0px rgba(50, 50, 50, 0.75);
  z-index: 99;
}

.ie8 .modal {
  filter: aplha(opacity=1);
}
